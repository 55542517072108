.primary-color {
	color: #2563eb;
}
.border-and-shadow {
	border: 1px solid rgba(231, 234, 243, 0.7);
	box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
}

/* statics section start */
.statics-gradient-border {
	position: relative;
	background: linear-gradient(to right, #d97706, #059669,  #9333ea);
	padding: 2px;
}
.stats-data-1 {
	color: #d97706;
}
.stats-data-2 {
	color: #059669;
}

.stats-data-4 {
	color: #9333ea;
}
/* statics section end  */

.swiper {
	width: 100%;
	height: 100%;
}
